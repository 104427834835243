<template>
  <div>
    <div
      class="h-100 click py-2"
      v-if="p._id"
      @click="view()"
      style="min-width: 160px; max-width: 100%"
    >
      <div class="border">
        <b-img-lazy
          class="img-fluid w-100"
          :src="getImage(p.image)"
        ></b-img-lazy>
      </div>

      <div class="px-2 py-1 position-relative">
        <div
          class="
            badge badge-info
            rounded-0
            py-2
            position-absolute
            px-3
            promo-badge
          "
          style="top: -90px; left: 0px"
          v-if="p.promos && p.promos.length > 0"
        >
          <span style="font-size: 12px"> PROMOCION </span>
          <span class="d-none promo-detail">{{ getPromo(p.value, p.promos) }}</span>
        </div>
        <div
          class="
            badge badge-warning
            rounded-0
            py-2
            position-absolute
            w-100
            category-badge
          "
          style="top: -120px; left: 0px"
          v-if="p.stock !== false && p.stock < 1"
        >
          <span style="font-size: 12px"> SIN STOCK </span>
        </div>
        <div
          class="badge badge-secondary rounded-0 position-absolute px-3"
          style="top: -40px; left: 0px"
        >
          <span style="font-size: 12px">
            {{ (p.category && p.category.name) || "Sin Categoria" }}
          </span>
        </div>
        <div
          class="badge badge-danger rounded-0 position-absolute"
          style="top: -20px; right: 0px"
        >
          <span style="font-size: 12px"> ${{ formatNumber(p.value) }} </span>
        </div>
        <div class="overflow-hidden pt-2">
          <span class="card-title text-uppercase font-weight-bold pr-2 product-title">
            {{ p.name }}
          </span>
        </div>
      </div>
      <div class="align-self-end w-100 px-2 mb-2 d-none">
        <div class="row">
          <div class="col-md-5 ml-auto">
            <router-link
              class="btn btn-outline-success text-center d-block btn-sm"
              :to="getLink(`producto/${p._id}`)"
            >
              <b-icon icon="cart" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data", "id"],
  data() {
    return {
      p: {},
    };
  },
  mounted() {
    if (!this.data) this.load();
    else this.p = this.data;
  },
  methods: {
    getPromo(value = 0, promos) {
      let rs = '';

      promos.forEach((promo) => {
        if (!promo.enabled) return;
        if (promo.to == "product")
          rs +=
            `$${value - promo.discount} sobre ${promo.value}` + " \n";
        if (promo.to == "total")
          rs +=
            `-$${promo.discount} al total sobre $${promo.value}` +
            " \n";
        if (promo.to == "option")
          rs +=
            `${promo.in == "$" ? "$" : ""}${promo.discount}${
              promo.in == "%" ? "%" : ""
            } dcto a ${promo.to}` + " \n";
        console.log({ promo });
      });
      return rs
    },
    view() {
      console.log("uwu");
      // push query pid
      this.$router.push({
        query: { pid: this.p._id },
      });
    },
    load() {
      const pid = this.id;
      const { webName: _id } = this.$store.state.info;
      this.$http
        .get(`${_id}/product/${pid}`)
        .then((res) => {
          this.p = res.data.data;
          //this.$store.commit("setProduct", res.data.data);
          console.log(res.data.data);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>